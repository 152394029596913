import React, { useState } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Modal, Button, Typography, Actions } from '../../components/library'

const profiles = [
    {
        role: 'Child',
        name: 'James',
        avatar: 'panda',
        id: 'b7dd4698-d0a5-11eb-b8bc-0242ac130003'
    },
    {
        role: 'Parent',
        name: 'Kristin',
        avatar: 'reindeer',
        id: '7cef7c68-6020-4c4f-a9b5-fac0423aa290'
    },
    {
        role: 'Pet',
        name: 'Charlie',
        id: '87a87f33-209c-4141-9271-19d0b3022109'
    },
];

function modal() {

    const [sampleOpen, setSampleOpen] = useState(false);
    const [sampleOpenVideo, setSampleOpenVideo] = useState(false);


    return (
        <LibraryWrapper>
            <Button onClick={(e) => setSampleOpen(true)} inline>Open Sample Modal</Button>

            <Button onClick={(e) => setSampleOpenVideo(true)} inline>Open Sample Video Modal</Button>

            <Modal
                id="sample-modal"
                open={sampleOpen}
                title="Sample Modal"
                profiles={profiles}
                subtitle="This is the subtitle"
                handleClose={(e) => setSampleOpen(false)}
                footer={<Actions 
                    dismissTo="/components"
                    dismissLabel="Go Back"
                    submitTo="/components"
                    submitLabel="Next"
                />}
            >
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
                
            </Modal>
            <hr />
            <Modal
                id="sample-modal-video"
                open={sampleOpenVideo}
                title="Sample Modal Video"
                profiles={profiles}
                subtitle="This is the subtitle"
                handleClose={(e) => setSampleOpenVideo(false)}
                dark
                videoSrc="/sample-video.mp4"
                videoMime="video/mp4"
                videoWidth={1920}
                videoHeight={1016}
                footer={<Button  variant="contained" size="large" color="primary">Get Results</Button>}
            >
                <Typography variant="body1" color="light">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
            </Modal>
        </LibraryWrapper>
    )
}
export default modal
